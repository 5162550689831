import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "textarea"
    }}>{`Textarea`}</h1>
    <p>{`Textarea and its variations.`}</p>
    <p>{`Same as input component, basically it's a HTML `}<inlineCode parentName="p">{`textarea`}</inlineCode>{` element with `}<inlineCode parentName="p">{`textarea`}</inlineCode>{` class. So all attributes it's comming for a regular HTML element.`}</p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
    <div className='col-sm-24 col-md-12 col-lg-8'>
  <label className='display-block' htmlFor='textarea-text'>
    Label
  </label>
  <div className='wrapper-input mb-3'>
    <textarea className='display-block textarea' type='text' name='textarea-text' id='textarea-text' placeholder='Placeholder'></textarea>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='textarea-text'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <textarea
        class='display-block textarea'
        type='text'
        name='textarea-text'
        id='textarea-text'
        placeholder='Placeholder'
      ></textarea>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "labeled"
    }}>{`Labeled`}</h2>
    <p>{`Place a label or button in any side of a textarea. You may also place on both sides. Inside `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` just add a label or button with `}<inlineCode parentName="p">{`input-group-text`}</inlineCode>{`.`}</p>
    <div className='col-sm-24 col-md-12 col-lg-8'>
  <label className='display-block' htmlFor='textarea-text-icon-3'>
    Label
  </label>
  <div className='wrapper-input mb-3'>
    <span className='input-group-text'>
      @
    </span>
    <textarea className='display-block textarea' type='number' name='textarea-text-icon-3' id='textarea-text-icon-3' placeholder='Placeholder'></textarea>
    <span className='input-group-text'>
      $
    </span>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='textarea-text-icon-3'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <span
        class='input-group-text'
      >
        @
      </span>
      <textarea
        class='display-block textarea'
        type='number'
        name='textarea-text-icon-3'
        id='textarea-text-icon-3'
        placeholder='Placeholder'
      ></textarea>
      <span
        class='input-group-text'
      >
        $
      </span>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "error"
    }}>{`Error`}</h2>
    <p>{`Textarea field can show the data contains errors. Add `}<inlineCode parentName="p">{`error`}</inlineCode>{` class on input and a element with `}<inlineCode parentName="p">{`box-validation box-invalid`}</inlineCode>{` below `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` in case of error message is need it.`}</p>
    <div className='col-24'>
  <label className='display-block' htmlFor='input-text-error'>
    Label
  </label>
  <div className='wrapper-input'>
    <textarea className='display-block textarea error' type='number' name='textarea-text-icon-3' id='textarea-text-icon-3' placeholder='Placeholder'></textarea>
  </div>
  <div className='box-validation box-invalid show mb-3'>
    <i className="fas fa-exclamation-triangle"></i>Error
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text-error'>
      Label
    </label>
    <div class='wrapper-input'>
      <textarea
        class='display-block textarea error'
        type='number'
        name='textarea-text-icon-3'
        id='textarea-text-icon-3'
        placeholder='Placeholder'
      ></textarea>
    </div>
    <div class='box-validation box-invalid show mb-3'>
      <i class="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "valid"
    }}>{`Valid`}</h2>
    <p>{`Textarea field can show that it is valid. Add `}<inlineCode parentName="p">{`valid`}</inlineCode>{` class on input and a element with `}<inlineCode parentName="p">{`box-validation box-valid`}</inlineCode>{` below `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` in case of a valid message is need it.`}</p>
    <div className='col-sm-24 col-md-12 col-lg-8'>
  <label className='display-block' htmlFor='input-text-valid'>
    Label
  </label>
  <div className='wrapper-input'>
    <textarea className='display-block textarea valid' type='number' name='textarea-text-icon-3' id='textarea-text-icon-3' placeholder='Placeholder'></textarea>
  </div>
  <div className='box-validation box-valid show mb-3'>
    <i className="fas fa-check-circle"></i>Valid
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text-valid'>
      Label
    </label>
    <div class='wrapper-input'>
      <textarea
        class='display-block textarea valid'
        type='number'
        name='textarea-text-icon-3'
        id='textarea-text-icon-3'
        placeholder='Placeholder'
      ></textarea>
    </div>
    <div class='box-validation box-valid show mb-3'>
      <i class="fas fa-check-circle"></i>Valid
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "readonly"
    }}>{`Readonly`}</h2>
    <p>{`Textarea field can show that it is readonly. Add `}<inlineCode parentName="p">{`readonly`}</inlineCode>{` attribute/class on textarea.`}</p>
    <div className='col-sm-24 col-md-12 col-lg-8'>
  <label className='display-block' htmlFor='textarea-text-readonly'>
    Label
  </label>
  <div className='wrapper-input mb-3'>
    <textarea className='display-block textarea' type='text' name='textarea-text-readonly' id='textarea-text-readonly' value='Readonly field' placeholder='Placeholder' readOnly></textarea>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='textarea-text-readonly'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <textarea
        class='display-block textarea'
        type='text'
        name='textarea-text-readonly'
        id='textarea-text-readonly'
        value='Readonly field'
        placeholder='Placeholder'
        readonly
      ></textarea>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Textarea field can show that it is disabled. Add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute/class on textarea.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text'>
      Label
    </label>
    <div className='wrapper-input'>
      <textarea className='display-block textarea' type='number' name='textarea-text-icon-3' id='textarea-text-icon-3' placeholder='Placeholder' disabled></textarea>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text'>
      Label
    </label>
    <div class='wrapper-input'>
      <textarea
        class='display-block textarea'
        type='number'
        name='textarea-text-icon-3'
        id='textarea-text-icon-3'
        placeholder='Placeholder'
        disabled
      ></textarea>
    </div>
  </div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      